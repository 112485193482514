export function AA_Cart_Messages_ISSET(arr, var_args) {
  for (var i = 1, k = arguments.length; i < k; ++i) {
    if (!arr || !arr.hasOwnProperty(arguments[i])) return false;
    arr = arr[arguments[i]];
  }
  return true;
}

export function AA_Cart_Messages_Font_Loader(font) {
  let fetchStyle = function (url) {
    return new Promise((resolve, reject) => {
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.onload = function () {
        resolve();
      };
      link.href = url;

      let headScript = document.querySelector('script');
      headScript.parentNode.insertBefore(link, headScript);
    });
  };

  if (font == 'Theme Default') return false;
  if (font == 'Arial') return false;

  if (!AA_Cart_Messages.google_fonts.includes(font)) {
    fetchStyle(
      `https://fonts.googleapis.com/css?family=` + font.replace(' ', '+')
    );
    AA_Cart_Messages.google_fonts.push(font);
  }
}
