import {
  AA_Cart_Messages_ISSET as isset,
  AA_Cart_Messages_Font_Loader as GoogleFontUploader,
} from './utils.js';

document.addEventListener('DOMContentLoaded', function (event) {
  if (isset(AA_Cart_Messages.settings, 'layout-1-font-family')) {
    GoogleFontUploader(AA_Cart_Messages.settings['layout-1-font-family']);
  }

  if (isset(AA_Cart_Messages.settings, 'layout-2-font-family')) {
    GoogleFontUploader(AA_Cart_Messages.settings['layout-2-font-family']);
  }

  if (isset(AA_Cart_Messages.settings, 'layout-3-font-family')) {
    GoogleFontUploader(AA_Cart_Messages.settings['layout-3-font-family']);
  }

  if (isset(AA_Cart_Messages.settings, 'layout-4-font-family')) {
    GoogleFontUploader(AA_Cart_Messages.settings['layout-4-font-family']);
  }
});
